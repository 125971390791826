import { PipelineStepBase } from './PipelineStepBase';
import { PipelineStepType } from './PipelineStepType';

export interface DataProcessingStep extends PipelineStepBase {
  type: PipelineStepType.data_processing;
  configuration: DataProcessingStepConfiguration;
}

export type DataProcessingStepConfiguration = {
  function_link: string;
  kwargs: Record<string, { value: string; type: ArgsType }>;
  pass_function_runner_logger: boolean;
  job_timeout: number;
  job_size?: JobSize;
  ecr_image?: ECRImage;
  queue_priority: QueuePriority;
  processing_type: ProcessingType;
  script_signature?: string;
  instance_name?: string;
  can_be_stopped?: boolean;
};

export type DataProcessingArgs = Record<string, { value: string; type: ArgsType }>;

export type ArgsType = 'text' | 'boolean' | 'number' | 'dict' | 'list' | 'date';

export enum ECRImage {
  ApiProject = 'api_project',
  ApiMaster = 'api_master',
  ApiUat = 'api_uat',
  ApiUatPy311 = "api_uat_py_3_11",
  ApiMasterPy311 = 'api_master_py_3_11',
  Python39Image = 'python_39_image',
  EventsDevelop = 'events_develop',
  EventsMain = 'events_main',
  TargetsDevelop = 'targets_develop',
  TargetsMain = 'targets_main',
  VFStandardLoadsDev = 'vf_standard_loads_dev',
  VFStandardLoadsMain = 'vf_standard_loads_main',
}

export enum JobSize {
  FargateMicro = 'Micro (2 GB)',
  FargateSmall = 'Small (4 GB)',
  FargateMedium = 'Medium (8 GB)',
  FargateLarge = 'Large (30 GB)',
  FargateXLarge = 'XLarge (64 GB)',
  FargateXXLarge = 'XXLarge (120 GB)',
}

export enum QueuePriority {
  high = 'high',
  low = 'low',
}

export enum ProcessingType {
  Managed = 'managed',
  Custom = 'custom',
}
